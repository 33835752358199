<template>
  <div>
    <h4 class="mr-sm-4 mb-0 header-tablepage mb-2">Log</h4>
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-table
        fixed
        striped
        hover
        :fields="fields"
        :busy="isBusy"
        show-empty
        :items="logList"
        empty-text="No matching records found"
      >
        <template v-slot:cell(created_time)="{ item }">
          {{ $moment(item.created_time).format("DD/MM/YYYY hh:mm:ss A") }}
        </template>
        <template v-slot:cell(response_code)="{ item }">
          <span
            :class="item.response_code == '200' ? 'text-success' : 'text-error'"
            >{{ item.response_code == "200" ? "Success" : "Fail" }}</span
          >
        </template>
        <template v-slot:cell(preview)="{ item }">
          <b-button variant="link" class="text-warning px-1 py-0">
            <font-awesome-icon
              icon="search"
              title="preview"
              @click="openModalPreview(item)"
            />
          </b-button>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>

      <Pagination
        @handleChangeTake="handleChangeTake"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </div>
    <ModalPreviewBody :tabIndex="3" ref="modal"></ModalPreviewBody>
  </div>
</template>
<script>
import ModalPreviewBody from "./modalPreviewBody.vue";
export default {
  components: { ModalPreviewBody },
  data() {
    return {
      logList: [],
      isBusy: false,
      fields: [
        {
          key: "id",
          label: "ID",
          class: "w-1",
        },
        {
          key: "name",
          label: "Name",
          class: "w-auto",
        },
        {
          key: "created_time",
          label: "Create Time",
          class: "w-auto",
        },
        {
          key: "url",
          label: "Url",
          class: "w-auto",
        },
        {
          key: "response_code",
          label: "Response Code",
          class: "w-20",
        },
        {
          key: "preview",
          label: "Preview Body",
          class: "w-1",
        },
      ],
      filter: {
        page: 1,
        take: 10,
      },
      rows: 0,
    };
  },
  methods: {
    async getData() {
      this.isBusy = true;
      const response = await this.axios.post(`/webhook/ListLog`, this.filter);
      this.isBusy = false;
      this.logList = response.data.detail.items;
      this.rows = response.data.detail.totalCount;
    },
    openModalPreview(item) {
      this.$refs.modal.openModal(item);
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.getData();
    },
    pagination(page) {
      this.filter.page = page;
      this.getData();
    },
  },
  created() {
    this.getData();
  },
};
</script>
